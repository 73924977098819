<template>
    <div class="address-index">
        <md-tabs ref="tabs" @change="tabChange">
            <md-tab md-label="Addresses" :md-active="tabIndex==0">
                <address-list ref="list" v-if="tabIndex==0"></address-list>
            </md-tab>
            <md-tab md-label="Add Address" :md-active="tabIndex==1">
                <address-edit ref="add" v-if="tabIndex==1"></address-edit>
            </md-tab>
            <md-tab md-label="Edit Address" v-if="tabIndex==2" :md-active="tabIndex==2">
                <address-edit ref="edit" :id="id" v-if="tabIndex==2"></address-edit>
            </md-tab>
        </md-tabs>
    </div>
</template>


<script>
import AddressList from "@/components/Address/AddressList";
import AddressEdit from "@/components/Address/AddressEdit";
export default {
    props: ["id"],
    components: {
        AddressList,
        AddressEdit
    },
    data() {
        return {
            tabIndex: 0
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (!this.id) {
                    this.tabIndex = 0;
                } else if (this.id == "new") {
                    this.tabIndex = 1;
                } else {
                    this.tabIndex = 2;
                }
            }
        }
    },
    methods: {
        tabChange(index) {
            if (index == 0) {
                this.$router.push({ path: "/admin/addresses", query: this.$route.query });
            }
            if (index == 1) {
                this.$router.push({ path: "/admin/addresses/new", query: this.$route.query });
            }
            if (index == 2) {
                this.$router.push({ path: `/admin/addresses/${this.id}`, query: this.$route.query });
            }
        }
    }
};
</script>