<template>
    <div class="address-list">
        <list :fs="addressFields" api="addresses" apiList="addresses/my" editLink="/admin/addresses" addLink="/admin/addresses/new"></list>
    </div>
</template>
<script>
import List from "@/components/List";
import { addressFields } from "@/config";
export default {
    components: {
        List
    },
    computed: {
        addressFields() {
            return addressFields;
        }
    }
};
</script>
