<template>
    <div class="address-edit">
        <edit ref="addressEdit" api="addresses" :id="id" :fs="addressFields" @saved="saved"></edit>
    </div>
</template>

<script>
import Edit from "@/components/Edit";
import { country, addressFields } from "@/config";
import addressAutoFill from "@/components/Address/AddressAutoFill";
export default {
    props: ["id"],
    mixins: [addressAutoFill],
    computed: {
        addressFields() {
            return addressFields;
        },
    },
    components: {
        Edit,
    },
    methods: {
        fillAddressFields(obj) {
            const record = this.$refs["addressEdit"].newRecord;
            this.$set(record, "city", obj.city);
            this.$set(record, "address", obj.address);
            this.$set(record, "state", obj.state);
            this.$set(record, "zip", obj.zip);
        },
        saved() {
            this.$router.push(this.$route.query.referrer || "/admin/addresses");
        },
    },
    mounted() {
        this.loadGooleMapScript();
        console.info("referrer", this.$route.query.referrer);
    },
};
</script>
<style>
.edit-fields .edit-field:nth-last-child(1) {
    display: none;
}
</style>